<script setup lang="ts">
import { useWindowScroll } from "@vueuse/core";
import VText from "@magnit/core/src/components/VText/VText.vue";
import BackIcon from "@magnit/icons/src/assets/icons/arrow-left-corner-24.svg";
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";

const props = defineProps<{
  text?: string;
  route?: string;
  fromCategory?: string;
}>();
const router = useRouter();
const { y } = useWindowScroll({ behavior: "smooth" });

const handleButtonClick = () => {
  if (props.route) {
    router.push(props.route);
  } else {
    router.back();
    y.value = 0;
  }
};
</script>

<template>
  <VInlineButton
    theme="secondary"
    icon-position="left"
    class="app-navigation-back"
    @click="handleButtonClick"
  >
    <template #icon>
      <BackIcon />
    </template>
    <VText v-if="text" font="body-large-accent">
      {{ text }}
    </VText>
  </VInlineButton>
</template>

<style scoped lang="postcss">
.app-navigation-back {
  margin: var(--pl-unit-x1) -6px;
}
</style>
